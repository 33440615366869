<template>
  <div class="personCenContainer">
    <div class="personBg"></div>
    <div class="abslteBg">
      <div class="personTop">
        <div class="personCont">
          <div class="presonAvar">
            <img style="border-radius: 100%" :src="cappicurl" />
          </div>
          <div class="presonInfo">
            <div class="personCon">
              <div class="personCon_child">
                <h2>{{ userInfo.empname }}</h2>
                <p>{{ userInfo.empcode }}</p>
                <p>{{ shopname }}</p>
                <p>店铺编码:{{ shopid }} 店长：{{ shopmanagerempname }}</p>
              </div>
            </div>
          </div>
          <div class="tool">
            <van-uploader
              accept="image/*"
              capture="camera"
              :max-count="1"
              :after-read="afterRead2"
              v-if="isshopmanager"
            >
              <van-button type="info" size="small" plain hairline
                >店长代签</van-button
              >
            </van-uploader>
            <br v-else />
            <!-- <van-button
              type="info"
              size="small"
              plain
              hairline
              @click="s1 = true"
              >店长代签</van-button
            > -->
            <van-button
              type="info"
              :icon="'arrow-down'"
              :icon-position="'right'"
              size="small"
              @click="show = true"
              >{{ date }}</van-button
            >
          </div>
        </div>
      </div>
      <div class="personList">
        <div class="btn_group" v-for="(item, index) in list" :key="index">
          <div class="nameTime">{{ item.checktype }} {{ item.time }}</div>
          <div class="address">
            <van-icon name="location" />{{ item.remark }}
          </div>
          <van-image
            v-if="item.pic"
            width="100"
            height="100"
            :src="item.pic"
            @click="_ImagePreview(item.pic)"
          />
        </div>
      </div>
      <van-uploader
        accept="image/*"
        capture="camera"
        :max-count="1"
        :after-read="afterRead1"
      >
        <van-button type="info">我要签到</van-button>
      </van-uploader>
    </div>

    <van-calendar :min-date="minDate" v-model="show" @confirm="onConfirm" />
    <van-popup v-model="s1" round position="bottom">
      <van-picker
        show-toolbar
        :columns="shoplist"
        value-key="partname"
        :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')"
        @cancel="s1 = false"
        @confirm="onConfirmShop"
      />
    </van-popup>
    <van-popup v-model="s2" round position="bottom">
      <van-picker
        show-toolbar
        :columns="emplist"
        value-key="partname"
        :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')"
        @cancel="s2 = false"
        @confirm="onConfirmEmp"
      />
    </van-popup>
    <van-popup v-model="s3" round position="bottom">
      <van-picker
        show-toolbar
        :columns="typelist"
        value-key="partname"
        :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')"
        @cancel="s2 = false"
        @confirm="onConfirmType"
      />
    </van-popup>
    <van-popup v-model="s4" round position="bottom">
      <van-datetime-picker
        v-model="currentTime"
        type="datetime"
        title="选择签到时间"
        :min-date="minDate"
        @confirm="onConfirmTime"
      />
    </van-popup>
  </div>
</template>
<script>
import { Toast, ImagePreview, ref } from 'vant'
import { parseTime } from '@/utils'
import {
  GetJsSign,
  PhoneSign,
  managerPhoneSign,
  ini,
  getData
} from '@api/hp.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      minDate: new Date(2020, 0, 1),
      cappicurl: require('@assets/img/loginLogo.png'),
      interval: null,
      show: false,
      date: parseTime(new Date(), '{y}/{m}/{d}'),
      currentTime: new Date(),
      latitude: 0,
      longitude: 0,
      list: [],
      shoplist: ['调动出发', '调动到达'],
      emplist: [],
      typelist: ['上班', '下班', '调动出发', '调动到达'],
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      base64: '',
      shopname: '',
      shopid: '',
      shopmanagerempname: '',
      isshopmanager: 0,
      datainfo2: [],
      datainfo3: [],
      checktype: '', // 代签类型
      empid: '' // 代签员工empid
    }
  },
  created() {
    if (window.APP_ID || true) this.weixinConfig()
    this._ini()
  },
  methods: {
    // 获取界面信息
    _ini() {
      ini({ empid: this.userInfo.empid }).then(res => {
        this.shopname = res.datainfo[0].shopname
        this.shopid = res.datainfo[0].shopid
        this.isshopmanager = res.datainfo[0].isshopmanager
        this.shopmanagerempname = res.datainfo[0].shopmanagerempname
        this.datainfo2 = res.datainfo2
        this.datainfo3 = res.datainfo3
        this._getData()
      })
    },
    // 获取wx jssdk并且开放 wx-open-launch-weapp 跳转小程序标签
    async weixinConfig() {
      let _that = this
      await this._GetJsSign()
      wx.ready(() => {
        console.log('config ready')
        // 7.2 获取当前地理位
        _that._getLocation()
        _that.interval = setInterval(() => {
          _that._getLocation()
        }, 120 * 1000)
      })
      wx.error(w => {
        console.log('config error', w)
      })
    },
    async _GetJsSign() {
      // let mm = encodeURIComponent(location.href)
      //在vue里，我们把encodeURIComponent去掉了，反倒问题解决了
      let mm = window.globalurls || location.href
      GetJsSign({
        param: mm
      }).then(res => {
        wx.config({
          debug: false,
          appId: res.appId,
          timestamp: res.timestamp,
          nonceStr: res.nonceStr,
          signature: res.signature,
          jsApiList: ['openLocation', 'getLocation']
        })
      })
    },
    //获取当前地理位
    _getLocation() {
      let that = this
      wx.getLocation({
        type: 'wgs84',
        isHighAccuracy: true,
        highAccuracyExpireTime: 5000,
        // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function(res) {
          console.log('success', res.latitude, res.longitude)
          that.latitude = res.latitude
          that.longitude = res.longitude
        },
        cancel: function(res) {
          console.log('cancel', res)
        },
        fail: function(res) {
          console.log('fail', res)
        }
      })
    },
    // 日历关闭回调
    onConfirm(date) {
      this.date = parseTime(date, '{y}/{m}/{d}')
      this.show = false
      this._getData()
    },
    // 获取考勤记录
    _getData() {
      getData({ empid: this.userInfo.empid, kqdate: this.date }).then(res => {
        this.list = res.datainfo
        this.iniDate(this.list)
      })
    },
    // 格式化每天签到数据
    iniDate() {
      this.list.forEach(e => {
        e.time = e.kqdate.split('T')[0] + ' ' + e.kqtime + ':' + e.kqsec
        e.pic =
          cloundserver +
          '/AttachFile/m610/m_610_' +
          e.autoid +
          '_' +
          e.sfilename +
          e.fileext
      })
    },
    // 拍照1（签到）
    async afterRead1(file) {
      this.base64 = file.content
      this.s3 = true
    },
    // 拍照2（代签）
    async afterRead2(file) {
      this.base64 = file.content
      this.s1 = true
    },
    // 预览图片
    _ImagePreview(pic) {
      ImagePreview([pic])
    },
    // 店长选择代签类型
    onConfirmShop(value) {
      this.checktype = value

      this.emplist =
        value == '调动到达'
          ? this.datainfo2.map(e => {
              return e.empname
            })
          : this.datainfo3.map(e => {
              return e.empname
            })
      this.s1 = false
      this.s2 = true
    },
    // 选择员工
    onConfirmEmp(value, index) {
      this.empid =
        this.checktype == '调动到达'
          ? this.datainfo2[index].empid
          : this.datainfo3[index].empid
      this.s2 = false
      this.s4 = true
    },
    onConfirmTime() {
      this.s4 = false
      managerPhoneSign({
        shopmanagerempid: this.userInfo.empid,
        empid: this.empid,
        checktype: this.checktype,
        pic: this.base64.split(';base64,')[1],
        checkdatetime: parseTime(this.currentTime, null)
      }).then(res => {
        if (res.error == '1') {
          Toast.fail(res.msg)
        } else {
          Toast.success('代签成功！')
        }
        this.s4 = false
      })
    },
    // 选择签到类型
    onConfirmType(value) {
      PhoneSign({
        empid: this.userInfo.empid,
        checktype: value,
        pic: this.base64.split(';base64,')[1],
        lng: this.longitude,
        lat: this.latitude
      }).then(res => {
        if (res.error == '1') {
          Toast.fail(res.msg)
        } else {
          Toast.success('签到成功！')
        }
        this._getData()
        this.s3 = false
      })
    }
  }
}
</script>

<style scoped lang="less">
.personCenContainer {
  width: 100%;
  height: calc(100vh - 1.89333rem);
  position: relative;
}
.personBg {
  width: 100%;
  height: 142px;
  background: #2b8df0;
  position: relative;
  z-index: 1;
}
.abslteBg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  overflow: hidden;
}
.personTop {
  // width: 92%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
  background: #ffffff;
  border-radius: 16px;
  margin: 0 30px;
  margin-top: 40px;
  .personCont {
    padding: 40px 40px 40px 40px;
    display: flex;
  }
}
.presonAvar {
  width: 122px;
  min-width: 122px;
  height: 122px;
  border-radius: 122px;
  border: 2px solid #2b8df0;
  img {
    width: 100%;
    height: 100%;
  }
}
.presonInfo {
  width: 100%;
  height: 122px;
  display: flex;
}
.tool {
  width: 500px;
  .van-button {
    width: 100%;
  }
  .van-uploader {
    width: 100%;

    /deep/ .van-uploader__input-wrapper {
      width: 100%;
      .van-button {
        width: 100%;
      }
    }
  }
}
.personCon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 15px;
  .personCon_child {
    width: 100%;
    h2 {
      margin: 0;
      font-size: 34px;
      padding: 0;
      line-height: 48px;
      color: #333333;
      width: 100%;
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      padding: 0;
      color: #999999;
      font-size: 24px;
      width: 100%;
    }
  }
}
.personList {
  height: calc(100% - 500px);
  overflow: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
  background: #ffffff;
  border-radius: 16px;
  margin: 0 30px;
  margin-top: 40px;
  padding: 20px;
  .btn_group:first-child {
    margin-top: 0;
  }
  .btn_group {
    border-bottom: 1px #ccc dotted;
    font-size: 30px;
    padding: 20px 0;
    .nameTime {
      font-weight: bold;
    }
    .address {
      color: #999;
    }
  }
}
</style>
